import LinearProgress from '@mui/material/LinearProgress';
import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { MaintenanceMessage } from '../components/maintenance-message';
import { Navigation } from '../components/navigation';
import { TokenError } from '../components/token-error';
import { AuthContext } from '../contexts/auth-context';
import { captureError } from '../utils/capture-error';
import { checkForRedirectPath } from '../utils/redirect';

export function Root() {
  // Context
  const { state } = React.useContext(AuthContext);
  // State
  const [checking, setChecking] = React.useState(true);
  const [hasChecked, setHasChecked] = React.useState(false);
  const [maintData, setMaintData] = React.useState({
    checking: true,
    maintenanceActive: false,
    maintenanceBegin: '',
    maintenanceEnd: '',
  });
  // Hooks
  const navigate = useNavigate();

  React.useEffect(() => {
    const checkForMaintenance = async () => {
      try {
        if (process.env.REACT_APP_MAINT_URL) {
          const body = { data: { origin: window.origin } };
          const response = await fetch(process.env.REACT_APP_MAINT_URL, {
            body: JSON.stringify(body),
            headers: { 'Content-Type': 'application/json' },
            method: 'POST',
          });
          const data: {
            data: {
              maintenanceActive: boolean;
              maintenanceBegin: string;
              maintenanceEnd: string;
            };
          } = await response.json();
          setMaintData((currentState) => ({ ...currentState, ...data.data }));
        }
      } catch (error) {
        captureError({ data: { error } });
      } finally {
        setMaintData((currentState) => ({ ...currentState, checking: false }));
      }
    };

    // Check for the path after the authentication context is done checking
    // if the user is signed in
    if (!state.isChecking) {
      checkForMaintenance();
    }
  }, [state.isChecking]);

  React.useEffect(() => {
    const checkForPath = async () => {
      try {
        setChecking(true);
        setHasChecked(true);
        // Check if there is a path stored in the browser to
        // redirect the user to after logging in
        const pathname = await checkForRedirectPath();
        if (pathname) {
          // Route the user to the path
          navigate(pathname);
        }
      } catch (error) {
        captureError({ data: { error } });
      } finally {
        setChecking(false);
      }
    };

    // Check for the path after the authentication context is done checking
    // if the user is signed in
    if (!state.isChecking && !hasChecked) {
      checkForPath();
    }
  }, [navigate, state.isChecking, hasChecked]);

  const isLoading = state.isChecking || checking || maintData.checking;

  if (isLoading) {
    return <LinearProgress color="primary" />;
  } else if (maintData.maintenanceActive) {
    // Display a Maintenance Message
    return <MaintenanceMessage maintenanceEnd={maintData.maintenanceEnd} />;
  } else {
    return (
      <div>
        <Navigation />

        <TokenError />

        <Outlet />
      </div>
    );
  }
}
