import LinearProgress from '@mui/material/LinearProgress';
import { Auth, Hub } from 'aws-amplify';
import React from 'react';
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom';
import { RedirectSignIn } from './components/redirect-sign-in';
import { AppProvider } from './contexts/app-context';
import { AuthContext } from './contexts/auth-context';
import { usePermissions } from './hooks/use-permissions';
import { CarrierTools } from './routes/carrier-tools';
import { Course } from './routes/course';
import { Courses } from './routes/courses';
import { Featured } from './routes/featured';
import { Glossary } from './routes/glossary';
import { Grid } from './routes/grid';
import { Home } from './routes/home';
import { ProductGuide } from './routes/product-guide';
import { Resources } from './routes/resources';
import { Root } from './routes/root';
import { Video } from './routes/video';
import { Videos } from './routes/videos';
import { ARC_GROUP } from './types';

const AppRoutes = {
  Account: React.lazy(() => import('./routes/account')),
  Admin: React.lazy(() => import('./routes/admin')),
};

export function App() {
  // Context
  const { dispatch, state } = React.useContext(AuthContext);
  // Hooks
  const { checkPermissions } = usePermissions();

  React.useEffect(() => {
    // Amplify has a local eventing system called Hub
    // Amplify’s Auth category publishes in the auth channel
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
          dispatch({ type: 'USER_SET', payload: data });
          break;
        case 'tokenRefresh_failure':
          dispatch({ type: 'TOKEN_ERROR' });
          break;
        case 'signOut':
          dispatch({ type: 'USER_CLEAR' });
          break;
      }
    });

    const checkForUser = async () => {
      try {
        dispatch({ type: 'SET_CHECKING', payload: true });

        // Used to check if a user is logged in when the page is loaded
        const user = await Auth.currentAuthenticatedUser();
        dispatch({ type: 'USER_SET', payload: user });

        // Provides decoded JWT Tokens
        const userSession = await Auth.currentSession();
        dispatch({ type: 'SESSION_SET', payload: userSession });
      } catch (error) {
        // Will throw an error if there is no user logged in
        dispatch({ type: 'USER_CLEAR' });
      } finally {
        dispatch({ type: 'SET_CHECKING', payload: false });
      }
    };

    checkForUser();
  }, [dispatch]);

  const isAuthenticated = state.user !== undefined;
  const isAdmin = checkPermissions([ARC_GROUP.ARC_Marketing]);

  const navigateHome = <Navigate replace to="/" />;

  return (
    <AppProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Root />}>
            <Route index element={<Home />} />
            <Route path="featured" element={<Featured />} />
            <Route path="courses/:courseId" element={<Course />} />
            <Route path="courses" element={<Courses />} />
            <Route path="videos/:videoId" element={<Video />} />
            <Route path="videos" element={<Videos />} />
            <Route path="resources" element={<Resources />} />
            <Route path="resources/grids/:gridId" element={<Grid />} />
            <Route path="resources/product-guide" element={<ProductGuide />} />
            <Route path="resources/glossary" element={<Glossary />} />
            <Route path="resources/carrier-tools" element={<CarrierTools />} />
            <Route
              path="account"
              element={
                isAuthenticated ? (
                  <React.Suspense fallback={<LinearProgress />}>
                    <AppRoutes.Account />
                  </React.Suspense>
                ) : (
                  <RedirectSignIn />
                )
              }
            />
            <Route
              path="admin/*"
              element={
                isAdmin ? (
                  <React.Suspense fallback={<LinearProgress />}>
                    <AppRoutes.Admin />
                  </React.Suspense>
                ) : isAuthenticated ? (
                  <Navigate replace to="/account" />
                ) : (
                  <RedirectSignIn />
                )
              }
            />
            <Route path="*" element={navigateHome} />
          </Route>
        </Routes>
      </Router>
    </AppProvider>
  );
}
